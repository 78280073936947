<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>FAQ</span></div>

    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/contacts/faq">문의하기</router-link> > 
        <span>FAQ</span>
    </div>
    
    <!-- <div class="sub-tit">
      <h3>FAQ</h3>
      <span></span>
    </div> -->
    <!-- navigator -->
    <div id="content">
        <div class="sub-tit portfolio-wrap lnbWrap">
            <ul class="tab-tab" id="lnb">
                <li tab="0" class="active">
                <router-link to="/contacts/faq">FAQ</router-link>
                </li>
                <li tab="1" >
                <router-link to="/contacts/qna">Q&A</router-link>
            </li>
            </ul>
        </div>
        <div class="prdt-list2 mt0">
            <div class="space-box relative">
                <div class="space-page-body home-page relative">
                    <div class="space-archive-section box-100 relative homepage-block " style="transform: none;">
                        <div class="space-archive-section-home-ins space-page-wrapper relative border_bottom" style="transform: none;">

                            <div id="sb-search">
                                <span class="total">전체 <strong>{{list.length}}</strong>건</span>
                                <fieldset>
                                    <legend>게시판 검색</legend>
                                    <div class="where">
                                        <select name="sel_search" id="sel_search" v-model="type">
                                            <option value="">전체</option>
                                            <option value="title">제목</option>
                                            <option value="content">내용</option>
                                        </select>
                                    </div>
                                    <div class="search_inp inp">
                                        <div>
                                            <input type="text" name="txt_search" id="txt_search" class="keyword" placeholder="검색어를 입력해주세요." v-model="search" maxlength="30" @change="get_list">
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="transaction_withdrawal">
                                <div class="space-content-section box-75 left relative">
                                    <div class="box-100 relative">
                                        <div class="board_list_box">
                                            <ul class="board_list faq">
                                                <li v-for="(item,index) in list" :key="index" @click="select = index" :class="{'on': select == index}">
                                                    <div class="title_box flex">
                                                        <h3 class="title">{{item.title}}</h3>
                                                    </div>
                                                    <div class="text_box">
                                                        {{item.content}}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
    data(){
        return{
            select: -1,
            type: '',
            search: '',
            detail: {},

            list: []
        }
    },
    mounted() {
        this.get_list();
    },
    methods: {     
        get_list() {
            const type = this.type;
            const search = this.search;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/contact/faq/list`, {type, search}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.list = res.data.info;
                    }
                }
            )
        },
    },
}
</script>

<style scope>
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

#sb-search .search_inp {
    display: block;
    width:100%
}

@media screen and (max-width: 750px)
{
    #sb-search .inp {
        padding-right: 35px;
        width: 85%;
    }
}
</style>