<template>
    <section id="sub">
        <div class="sub-vis main-banner"><span>Q&A</span></div>

        <div class="container breadcrumbs">
            <router-link to="/"><i class="fal fa-home"></i></router-link> > 
            <router-link to="/contacts/faq">문의하기</router-link> > 
            <router-link to="/contacts/qna">Q&A</router-link> > 
            <span>Q&A 상세보기</span>
        </div>

        <div id="content">
            <div id="sb-wrap" class="w-100" v-if="!passwordTF">
                <div id="sb-password">
                    <fieldset>
                        <h4>비밀번호 입력</h4>
                        <input type="password" name="pwd" class="inp" v-model="password">
                    </fieldset>
                    
                    <div id="sb-footer">
                        <div class="center">
                            <button class="sb-btn type1" @click="passwordCheck">확인</button>
                            <router-link class="sb-btn type2" to="/contacts/qna">취소</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100" v-else>
                <dl class="prdt-view">
                    <dd>
                        <div class="head">
                            <h4>{{detail.title}}</h4>
                            <small style="float:right; margin: 14px 0px;">{{detail.create_time}}</small>
                        </div>
                        
                        <div id="sb-list">
                            <table class="list_tbl">
                                <colgroup>
                                    <col style="width: 50%;">
                                    <col style="width: 50%;">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td class="writer">제품</td>
                                        <td class="date">{{detail.product_name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="writer">사이즈</td>
                                        <td class="date">{{detail.size}}</td>
                                    </tr>
                                    <tr>
                                        <td class="writer">위치</td>
                                        <td class="date">{{detail.location}}</td>
                                    </tr>
                                    <tr>
                                        <td class="writer">작성자</td>
                                        <td class="date">{{detail.writer}}</td>
                                    </tr>
                                    <tr>
                                        <td class="writer">이메일</td>
                                        <td class="date">{{detail.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="writer">연락처</td>
                                        <td class="date">{{detail.phone}}</td>
                                    </tr>
                                    <tr>
                                        <td class="writer">제목</td>
                                        <td class="date">{{detail.title}}</td>
                                    </tr>
                                    <tr class="mb_100">
                                        <td class="writer">내용</td>
                                        <td class="date" style="height:150px">{{detail.content}}</td>
                                    </tr>
                                    <tr v-if="detail.img != '' || detail != null" class="mb_100">
                                        <td class="writer">참고이미지</td>
                                        <!-- <td class="date show-img" :style="`background-image: url(${AWSURL + detail.img});`"></td> -->
                                        <td>
                                            <img :src="`${AWSURL + detail.img}`" style="width:100%" alt="">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </dd>
                </dl>
                <dl class="prdt-view" v-if="detail.answer != 0">
                    <dd>
                        <div class="head">
                            <h4>Re : {{detail.title}}</h4>
                            <small style="float:right; margin: 14px 0px;">{{answer.create_time}}</small>
                        </div>
                        <div class="txt nostyle">
                            <pre>{{answer.answer}}</pre>
                        </div>
                    </dd>
                </dl>
                <div id="sb-footer">
                    <div class="center">
                        <router-link :to="`/contacts/qna`" class="sb-btn type1">목록보기</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            index: this.$route.params.idx,
            passwordTF:false,
            password: '',

            detail: {},
            answer: {},

            title:'질문', //임시 데이터
            post_title:'견적 문의 드립니다',
            content:`컨텐츠가 노출될 공간`
        }
    },
    mounted() {
        
    },
    methods: {
        passwordCheck() {
            if(this.password == '') return alert('비밀번호를 입력해주세요.')

            const index = this.index;
            const password = this.password;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/contact/qna/password/check`, {index, password}
            ).then(
                res => {
                    if(res.status == 200) {
                        if(res.data.code == '200'){
                            this.passwordTF = true
                            this.detail_data();
                        }
                        else {
                            alert('비밀번호가 다릅니다.')
                            this.password = '';
                        }
                    }
                }
            )
        },
        detail_data() {            
            const index = this.index;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/contact/qna/detail`, {index}
            ).then(
                res => {
                    if(res.status == 200) {
                        const detail = res.data.info;
                        this.detail = detail;
                        if(detail.answer != 0) this.answer_data();
                    }
                }
            )
        },
        answer_data() {            
            const index = this.index;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/contact/qna/answer/detail`, {index}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.answer = res.data.info;
                    }
                }
            )
        }
    },
}
</script>

<style scoped>
.head em{
    text-transform:uppercase
}

#sb-password fieldset .inp{
    display: inline-block;
    vertical-align: middle;
    outline: none;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    background: #ffffff;
    border: 1px solid #dadada;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.sb-btn{
    margin:0 5px;
}
.prdt-tab1{margin-bottom: 40px;}
.prdt-tab1 li{display: inline-block;width: 20%;border: 1px solid #ddd;margin: 0 -1px -1px 0;box-sizing: border-box;}
.prdt-tab1 li a{display: block;text-align: center;font-size: 15px;line-height: 45px;text-decoration: none;color: #000;}
.prdt-tab1 li.active{border-color: #313f64;}
.prdt-tab1 li.active a{background: #313f64;color: #fff;}

.prdt-tab2{border-top: 2px solid #313f64;background: #f7f7f7;border-bottom: 1px solid #ddd;margin-bottom: 40px;text-align: center;padding: 20px 0;}
.prdt-tab2 li{display: inline-block;margin: 5px 10px;}
.prdt-tab2 li a{font-size: 14px;color: #000;}

.prdt-list{margin: -1%;}
.prdt-list li{position: relative;display: inline-block;margin: 1%;width: 23%;vertical-align: top;}
.prdt-list li .link{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 2;}
.prdt-list li .tmb{position: relative;padding-bottom: 100%;background-position: center center;background-repeat: no-repeat;background-size: cover !important;}
.prdt-list li .tmb:before{content: '';display: block;position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 1px solid rgba(0,0,0,0.1);box-sizing: border-box;transition: all 0.1s;}
.prdt-list li:hover .tmb:before{border: 4px solid #313f64;}
.prdt-list li .info{padding: 20px 0;}
.prdt-list li .info > span{display: block;text-align: center;}
.prdt-list li .info .tit{font-size: 18px;color: #222222;letter-spacing: -1px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-weight: 300;}
.prdt-list li .info .txt{font-size: 14px;color: #888888;letter-spacing: -1px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.prdt-no-data{margin: 50px 0;text-align: center;font-size: 14px;color: #999;background: #f7f7f7;padding: 40px 0;font-style: italic;}

/* view */
.prdt-view{zoom: 1; width:100%;padding-bottom: 50px;}
.prdt-view dt .bigimg{position: relative;}
.prdt-view dt .bimg {display: block;max-width: 100%;height: auto !important;}
.prdt-view dt .bigimg img{max-width: 100%;max-height:700px;height: auto;}
.prdt-view dt .tmb-list{margin: -0.5%;margin-top: 20px;}
.prdt-view dt .tmb-list li{position: relative;display: inline-block;margin: 0.5%;width: 24%;}
.prdt-view dt .tmb-list li a{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 2;}
.prdt-view dt .tmb-list li .tmb{position: relative;padding-bottom: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;}

.prdt-view dd .head{border-bottom: 1px solid #ddd;padding-bottom: 35px;margin-bottom: 35px;}
.prdt-view dd .head em{display: block;margin-bottom: 20px;font-style: normal;font-size: 17px;color: #505d7e;font-weight: 300;}
.prdt-view dd .head h4{display: block;font-size: 35px;line-height: 42px;color: #222222;letter-spacing: -1px;font-weight: normal;}
.prdt-view dd .txt{display: block;font-size: 16px;line-height: 26px;color: #888888;}
.prdt-view dd .down{margin-top: 40px;}
.prdt-view dd .down a{display: inline-block;margin-right: 2px;background: #313f64;line-height: 35px;padding: 0 20px;text-decoration: none;font-size: 14px;color: #fff;}

.prdt-tab{margin-bottom: 30px;border-bottom: 2px solid #666666;}
.prdt-tab li{position: relative;display: inline-block;vertical-align: top;margin-right: -1px;border: 1px solid #ddd;border-bottom: none;}
.prdt-tab li a{display: block;line-height: 45px;height: 45px;text-align: center;width: 210px;font-size: 16px;color: #999;letter-spacing: -1px;text-decoration: none;box-sizing: border-box;}
.prdt-tab li.active{border: 2px solid #666666;border-bottom: none;background: #fff;height: 47px;margin-bottom: -2px;z-index: 2;}
.prdt-tab li.active a{font-weight: 300;color: #333;}
.prdt-tab li.active a:after{content: '';display: inline-block;background: url(/superboard/images/sb_slt_spr.jpg) no-repeat center center;margin-left: 10px;width: 15px;height: 15px;vertical-align: middle;}

.prdt-tabCont{margin-bottom: 80px;}
.prdt-txtbox img{max-width: 100%;height: auto;}

@media screen and (max-width: 1200px) {

    /* list */
    .prdt-tab1{margin-bottom: 20px;}
    .prdt-tab1 li{width: 33.33%;}
    .prdt-tab1 li a{font-size: 13px;line-height: 35px;}

    .prdt-tab2{margin-bottom: 20px;padding: 15px 0;}
    .prdt-tab2 li{margin: 5px 8px;}
    .prdt-tab2 li a{font-size: 13px;}

    .prdt-list{margin: -1%;margin-top: 15px;}
    .prdt-list li{margin: 1%;width: 48%;}
    .prdt-list li .info{padding: 10px 0;}
    .prdt-list li .info .tit{font-size: 14px;}
    .prdt-list li .info .txt{font-size: 13px;}

    .prdt-no-data{margin: 50px 0;font-size: 13px;padding: 20px 0;}

    /* view */
    .prdt-view{padding-bottom: 40px;}
    .prdt-view > *{float: none;}
    .prdt-view dt{padding-right: 0;width: auto;}
    .prdt-view dt .bigimg{text-align: center;}
    .prdt-view dt .bigimg img{max-height:500px;}
    .prdt-view dt .tmb-list{margin: -0.5%;margin-top: 10px;}

    /* .prdt-view dd{margin-top: 20px;padding-top: 10px;width: auto;} */
    .prdt-view dd .head {padding-bottom: 10px;margin-bottom: 10px;}
    .prdt-view dd .head em{font-size: 13px;margin-bottom: 5px;}
    .prdt-view dd .head h4{font-size: 16px !important;line-height: 20px !important;font-weight: 300;}
    .prdt-view dd .txt{font-size: 13px;line-height: 20px;}
    .prdt-view dd .down{margin-top: 20px;}
    .prdt-view dd .down a{font-size: 13px;}

    .prdt-tab{margin-bottom: 20px;}
    .prdt-tab li{width: 33.33%;box-sizing: border-box;}
    .prdt-tab li a{line-height: 35px;height: 35px;width: auto;font-size: 13px;}
    .prdt-tab li.active{height: 38px;}
    .prdt-tab li.active a:after{margin-left: 5px;width: 10px;height: 10px;background-size: contain;}

    .prdt-tabCont{margin-bottom: 40px;}
    .prdt-txtbox{font-size: 13px;line-height: 19px;}

}
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}
.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.show-img {
    background-size: cover;
    height: 500px;
}

@media screen and (max-width: 750px) {
    #sb-list .list_tbl tbody{
        display: block;
    }
    #sb-list .list_tbl tr{
        display: flex;
        width: 100%;
    }
    #sb-list .list_tbl tr td{
        display: block;
    }
    #sb-list .list_tbl tr td:first-child{
        width: 40%;
    }
    #sb-list .list_tbl tr td:last-child{
        width: 60%;
    }
    #sb-list .list_tbl tr.mb_100{
        display: block;
        width: 100%;
    }
    #sb-list .list_tbl tr.mb_100 td{
        display: block;
        width: calc(100% - 12px);
        word-break: break-all !important;
        overflow: auto;
    }

}
</style>