<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>{{select.name}}</span></div>

    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/term/policy">약관</router-link> > 
        <span>{{this.$route.params.idx}}</span>
    </div>
    <!-- navigator -->
    <div id="content">
        <div class="lnbWrap">
        <ul id="lnb">
            <li :class="{'active':$route.params.idx==item.link}" v-for="(item,index) in term" :key="index" @click="select=item">
                <router-link :to="`/term/${item.link}`">{{item.title}}</router-link>
            </li>
        </ul>
        </div>
        <div class="prdt-list2 mt0">
            <div class="space-box relative">
                <div class="space-page-body home-page relative">
                    <div class="space-archive-section box-100 relative homepage-block " style="transform: none;">
                        <div class="space-archive-section-home-ins space-page-wrapper relative" style="transform: none;">
                            <pre>{{select.content}}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  </section>
</template>

<script>
export default {
    watch: {
        $route(to, from) {
            if(this.term.length > 0){
                if (to.path != from.path) {
                    this.selectFn()
                }
            }
        } 
    },
    data(){
        return{
            select:{},
            term:[]
        }
    },
    created(){
        this.get_term();
    },
    methods:{
        selectFn(){
            switch (this.$route.params.idx){
                case 'policy':this.select = this.term[0];
                break;
                case 'private':this.select = this.term[1];
                break;
                case 'antiemail':this.select = this.term[2];
                break;
                default:this.select = this.term[0];
            }
        },        
		get_term() {
			const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/term`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.term = res.data.info;
                        this.select = res.data.info[0];
                    }
                }
            )
		}
    }
}
</script>
<style scoped>
    pre{
        white-space: pre-line;
    }
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}
</style>