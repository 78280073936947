<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>Q&A</span></div>

    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/contacts/faq">문의하기</router-link> > 
        <span>Q&A</span>
    </div>
    
    <!-- <div class="sub-tit">
      <h3>Q&A</h3>
      <span></span>
    </div> -->
    <!-- navigator -->
    <div id="content">
        <div class="sub-tit portfolio-wrap lnbWrap">
            <ul class="tab-tab" id="lnb">
                <li tab="0">
                <router-link to="/contacts/faq">FAQ</router-link>
                </li>
                <li tab="1" class="active">
                <router-link to="/contacts/qna">Q&A</router-link>
            </li>
            </ul>
        </div>
        <div class="prdt-list2 mt0">
            <div class="space-box relative">
                <div class="space-page-body home-page relative">
                    <div class="space-archive-section box-100 relative homepage-block" style="transform: none;">
                        <div class="space-archive-section-home-ins space-page-wrapper relative" style="transform: none;">

                            <div id="sb-search">
                                <span class="total">전체 <strong>{{list.length}}</strong>건</span>
                                <fieldset>
                                    <legend>게시판 검색</legend>
                                    <div class="where">
                                        <select name="sel_search" id="sel_search" v-model="type">
                                            <option value="">전체</option>
                                            <option value="title">제목</option>
                                            <option value="writer">작성자</option>
                                            <option value="content">내용</option>
                                        </select>
                                    </div>
                                    <div class="search_inp inp">
                                        <div>
                                            <input type="text" class="keyword" placeholder="검색어를 입력해주세요." v-model="search" maxlength="30" @change='get_list()'>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div id="sb-list">
                                <table class="list_tbl">
                                    <colgroup>
                                        <col style="width: 70px;">
                                        <col style="width: auto;">
                                        <col style="width: 100px;">
                                        <col style="width: 100px;">
                                        <col style="width: 160px;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th class="no">번호</th>
                                            <th class="sbj">제목</th>
                                            <th class="ans">답변여부</th>
                                            <th class="writer">작성자</th>
                                            <th class="date">작성일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in pageOfItems" :key="index">
                                            <td class="no">{{item.no}}</td>
                                            <td class="sbj" >
                                                <router-link :to="'/contacts/qna/'+item.no">
                                                {{item.title}} <i class="far fa-lock"></i> 
                                                </router-link>
                                            </td>
                                            <!-- <td class="sbj"  :class="{'reply1':item.answer == 1}">
                                                <router-link :to="'/contacts/qna/'+item.no">
                                                    <span v-if="item.answer == 1"> Re : </span>{{item.title}}<i class="far fa-lock"></i> 
                                                </router-link>
                                            </td> -->
                                            <td class="ans">
                                                <strong>{{transAns(item.answer)}}</strong>
                                            </td>
                                            <td class="writer">{{item.writer}}</td>
                                            <td class="date"><em>작성일</em>
                                            <span class="pc">{{item.create_time}}</span>
                                            <span class="mb">{{transDate(item.create_time)}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                
                                <nav aria-label="Page navigation example" style="text-align: center;" v-if="list.length > 0">
                                    <jw-pagination :items="list" :pageSize="10" :maxPages="5" @changePage="onChangePage" :labels="customLabels"></jw-pagination>
                                </nav>
                            </div>

                            <div id="sb-footer">
                                <div class="right">
                                    <router-link to="/contacts/qna/writer" class="sb-btn type1">등록</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import paginate from 'jw-paginate';
export default {
    data(){
        return{
            type: '',
            search: '',

            list: [],
            
            pageOfItems: [],
            customLabels: {}
        }
    },
    mounted() {
        this.get_list();
    },
    methods: {    
        transAns(x){
            if(x){
                return '답변완료'
            }else{
                return '미답변'
            }
        }, 
        transDate(x){
            return x.slice(0,10)
        },
        get_list() {
            const type = this.type;
            const search = this.search;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/contact/qna/list`, {type, search}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.list = res.data.info;
                    }
                }
            )
        },
        setPage(page) {
            const { items, pageSize, maxPages } = this;

            // get new pager object for specified page
            const pager = paginate(items.length, page, pageSize, maxPages);

            // get new page of items from items array
            const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

            // update pager
            this.pager = pager;

            // emit change page event to parent component
            this.$emit('changePage', pageOfItems);
        },
        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
    },
}
</script>

<style scoped>
.fa-lock{
    font-size: 12px;
    vertical-align: center;
}

.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.page-number>a{
    font-size: 17px;
    text-decoration: none;
    padding: 5px 12px;
    color: #96a0ad;
    line-height: 1.5;
}

#sb-search .search_inp {
    display: block;
    width:100%
}

@media screen and (max-width: 750px)
{
    #sb-search .inp {
        padding-right: 35px;
        width: 85%;
    }
}
</style>