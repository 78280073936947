<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>Q&A</span></div>

    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/contacts/faq">문의하기</router-link> > 
        <router-link to="/contacts/qna">Q&A</router-link> > 
        <span>Q&A 작성</span>
    </div>
    
    <!-- navigator -->
    <div id="content">
        <div class="prdt-list2 mt0 w-100">
            <div class="space-box relative">
                <div class="space-page-body home-page relative">
                    <div class="space-archive-section box-100 relative homepage-block " style="transform: none;">
                        <div class="space-archive-section-home-ins space-page-wrapper relative" style="transform: none;">

                            <div id="sb-write">
                                <table class="write_tbl">
                                    <colgroup>
                                        <col style="width: 150px;">
                                        <col style="width: auto;">
                                    </colgroup>
                                    <!-- 기본항목 -->
                                    <tbody>
                                        <tr>
                                            <th>제품종류</th>
                                            <td>
                                                <select name="type" v-model="product_cate" class="field w45" @change="get_product">
                                                    <option value=''>제품 카테고리를 선택해주세요.</option>
                                                    <option :value ="item.e_category" v-for="(item , index) in product_cate_list" :key="index">{{item.category}}</option>
                                                </select>                                                
                                                <select name="type" v-model="product_name" class="field w50 ml5" id="select_item">
                                                    <option value=''>제품을 선택해주세요.</option>
                                                    <option :value ="item.no" v-for="(item , index) in product_name_list" :key="index">{{item.product_name}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>사이즈</th>
                                            <td>
                                                <input type="text" class="inp w100" v-model="size" placeholder="가로X세로X두께의 사이즈를 적어주세요.">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>시공지역</th>
                                            <td>
                                                <input type="text" name="wm_name" class="inp w100" v-model="location" placeholder="(도,시,구,동) 을 입력해주세요">
                                            </td>
                                        </tr>
                                    </tbody>
                                    <br>
                                    <tbody>
                                        <tr>
                                            <th>작성자</th>
                                            <td><input type="text" name="wm_name" maxlength="20" class="inp w30" v-model="writer"></td>
                                        </tr>
                                        <tr>
                                            <th>이메일</th>
                                            <td><input type="text" name="wm_name" class="inp w100" v-model="email"></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">연락처</th>
                                            <td>
                                            <input type="text" name="wm_1" class="inp w100" maxlength="100" v-model="phone" placeholder="010-0000-0000">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비밀번호</th>
                                            <td>
                                                <input type="password" name="wm_password" id="wm_password" v-model="pw" class="inp w100">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비밀글</th>
                                            <td>* 해당글 작성시 무조건 비밀글로 설정됩니다. <input type="hidden" name="wm_secret" value="Y">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제목</th>
                                            <td>
                                            <input type="text" name="wm_title" id="wm_title" class="inp w100" v-model="title">
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!-- 내용 -->
                                    <tbody class="memo">
                                        <tr>
                                            <td colspan="2">
                                                <textarea v-model="content" placeholder="기타 특이사항 및 상세 설명을 작성해주세요"></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!-- 첨부파일 -->
                                    <tbody class="fileWrap">
                                        <tr>
                                            <th>첨부</th>
                                            <td>
                                                <input type="file" id="file" ref="file" @change="IMGS3PUT">
                                                <p class="warning-p"><small>여러장의 사진을 전송하시려면 ikanstone@naver.com 으로 전송바랍니다.</small></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div id="sb-footer">
                                <div class="left">
                                    <router-link to="/contacts/qna" class="sb-btn type3">목록</router-link>
                                </div>
                                <div class="right">
                                    <button class="sb-btn type1" @click="CheckConfirm">확인</button>
                                    <router-link to="/contacts/qna" class="sb-btn type2">취소</router-link>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import AWS from 'aws-sdk';
export default {
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            albumBucketName : this.$store.state.AWS.albumBucketName,
            bucketRegion : this.$store.state.AWS.bucketRegion,
            IdentityPoolId : this.$store.state.AWS.IdentityPoolId,

            product_cate_list: [],
            product_name_list: [],

            product_cate: '',
            product_name: '',
            size: '',
            location: '',

            writer: '',
            email: '',
            phone: '',
            pw: '',
            title: '',
            content: '',
            file_s3: '',
        }
    },
    mounted() {        
        this.get_category();
    },
    methods:{
        get_category() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/menu/category`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.product_cate_list = res.data.info;
                    }
                }
            )
        },
        get_product() {
            const color_num = 0;
            const post = this.product_cate;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/product/list`, {post, color_num}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.product_name_list = res.data.info;
                    }
                }
            )
        },
        IMGS3PUT:function() {
            var thumb = [];
            if(this.$refs.file !== undefined){
                thumb = this.$refs.file.files[0];
            }
            
            var reader  = new FileReader();

            reader.onloadend =()=>{
                this.file_s3 = reader.result;
            }
            
            if(thumb){
                reader.readAsDataURL(thumb);
                const file = thumb;

                var date = new Date();
                var timestamp = date.getTime();
                const filename = timestamp + file.name;

                AWS.config.update({region: this.bucketRegion,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
                var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.albumBucketName + '/question'}});
                s3.upload({Key: filename, Body : file, ACL:'public-read','ContentType': file.type}, (err)=>{
                    if(err != null){
                        console.log('Thumbnail Upload Error : '+err);
                        alert("파일업로드에 실패하였습니다. 다시 시도해주세요.");
                        return;
                    }
                    else {
                        this.file_s3 = 'question/'+filename;
                    }
                })                
            }
            else{
                this.file_s3 = '';
                alert("파일업로드에 실패했습니다. 다시 시도해주세요.");
                return;
            }

        },
        CheckConfirm() {
            if(this.product_cate == '') return alert('제품 카테고리를 선택해주세요.')
            else if(this.product_name == '') return alert('제품을 선택해주세요.')
            else if(this.size == '') return alert('사이즈를 입력해주세요.')
            else if(this.location == '') return alert('시공지역을 입력해주세요.')
            else if(this.writer == '') return alert('작성자를 입력해주세요.')
            else if(this.email == '') return alert('이메일을 입력해주세요.')
            else if(this.phone == '') return alert('연락처를 입력해주세요.')
            else if(this.pw == '') return alert('비밀번호를 입력해주세요')
            else if(this.title == '') return alert('제목을 입력해주세요.')
            
            this.QNA_IN_DB();
        },
        QNA_IN_DB() {
            const p_no = this.product_name;
            const size = this.size;
            const location = this.location;
            const writer = this.writer;
            const email = this.email;
            const phone = this.phone;
            const password = this.pw;
            const title = this.title;
            const content = this.content;
            const file = this.file_s3;

            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/contact/qna/insert`, {p_no, size, location, writer, email, phone, password, title, content, file}
            ).then(
                res => {
                    if(res.status == 200) {
                        const check = res.data.info;
                        if(check > 0){
                            alert('문의글을 등록하였습니다.')
                            this.$router.push('/contacts/qna');
                        }
                        else {
                            alert('문의글 등록에 실패하였습니다.')
                        }
                    }
                }
            )
        }
    },
}
</script>
<style scoped>

.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.w10 {
    width: 10%;
}

.w40 {
    width: 40%;
}

.w45{
    width: 45%;
}

.w50 {
    width: 50%;
}

.ml5{
    margin-left: 5%;
}

#sb-write .write_tbl textarea {
    width: 100%;
    height: 200px;
    font-size: 14px;
    line-height: 18px;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #ddd;
}

.warning-p {
    padding-top: 10px;
    color: #f34f4f;
}
</style>
